"use client";
import "@/css/globals.css";
import { useEffect, useState } from "react";
import { Logo } from "./Logo";
import { CloseIcon, FacebookIcon, InstagramIcon, SpotifyIcon } from "./Icons";
import Link from "next/link";

const NavbarPublic = () => {
  const genericHamburgerLine = `h-1 w-6 my-1 bg-white transition ease transform duration-300`;
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  useEffect(() => {
    const body = document.querySelector("body");
    if (body && window.innerWidth < 768) {
      if (isOpenMenu) {
        body.style.overflow = "hidden";
      } else {
        body.style.overflow = "auto";
      }
    }
  }, [isOpenMenu]);

  return (
    <div className="bg-gradient-to-r from-orange to-bright-pink">
      <div className="container mx-auto flex items-center justify-between p-4 text-cream">
        <div className="flex items-center">
          <Link href="/">
            <Logo height="40px" className="fill-cream" />
          </Link>
        </div>
        <div className="flex">
          <button
            className="flex flex-col h-10 w-10 justify-center items-center group md:hidden"
            onClick={() => setIsOpenMenu(!isOpenMenu)}
          >
            <div
              className={`${genericHamburgerLine} ${
                isOpenMenu
                  ? "rotate-45 translate-y-3 opacity-100 group-hover:opacity-100"
                  : "opacity-100 group-hover:opacity-100"
              }`}
            />
            <div
              className={`${genericHamburgerLine} ${
                isOpenMenu ? "opacity-0" : "opacity-100 group-hover:opacity-100"
              }`}
            />
            <div
              className={`${genericHamburgerLine} ${
                isOpenMenu
                  ? "-rotate-45 -translate-y-3 opacity-100 group-hover:opacity-100"
                  : "opacity-100 group-hover:opacity-100"
              }`}
            />
          </button>

          <div
            className={`absolute h-full w-full top-0 left-0 bg-bright-pink z-50 flex flex-col items-center justify-between py-12 ${
              isOpenMenu ? "scale-100" : "scale-0"
            }
            
            transition-transform duration-300 md:scale-100 md:relative md:flex-row md:py-0 md:bg-transparent`}
          >
            <div className="absolute top-5 right-5 md:hidden">
              <CloseIcon
                height="24px"
                className="fill-cream"
                onClick={() => setIsOpenMenu(!isOpenMenu)}
              />
            </div>
            <div className="md:hidden">
              <Logo height="50px" className="fill-cream translate-x-3" />
            </div>
            <div className="flex flex-col items-center gap-3 text-xl font-light md:flex-row md:text-lg">
              <Link
                href="/"
                className="text-white"
                onClick={() => setIsOpenMenu(!isOpenMenu)}
              >
                Inicio
              </Link>
              <Link
                href="/planes"
                className="text-white"
                onClick={() => setIsOpenMenu(!isOpenMenu)}
              >
                Planes
              </Link>
              <Link
                href="/maluxmindset"
                className="text-white"
                onClick={() => setIsOpenMenu(!isOpenMenu)}
              >
                Tienda
              </Link>
              <Link
                href="/coachs"
                className="text-white"
                onClick={() => setIsOpenMenu(!isOpenMenu)}
              >
                Equipo
              </Link>
              <Link
                href="/login"
                className="px-5 py-1 bg-cream rounded-full text-deep-purple"
                onClick={() => setIsOpenMenu(!isOpenMenu)}
              >
                Iniciar Sesión
              </Link>
              <Link
                href="/register"
                className="text-white"
                onClick={() => setIsOpenMenu(!isOpenMenu)}
              >
                Registrate Gratis
              </Link>
            </div>
            <div className="flex gap-3 md:hidden">
              <Link
                href="https://open.spotify.com/playlist/5MITlbdpej2vXOqOgsxUOr?si=607bb01d119c43d1"
                target="_blank"
              >
                <SpotifyIcon className="fill-cream" height="24px" />
              </Link>
              <Link
                href="https://www.facebook.com/maluperezestudio"
                target="_blank"
              >
                <FacebookIcon className="fill-cream" height="24px" />
              </Link>
              <Link
                href="https://www.instagram.com/malu_perez/"
                target="_blank"
              >
                <InstagramIcon className="fill-cream" height="24px" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarPublic;
